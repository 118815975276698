export default {
  name: 'ContentBlockPhrase',
  components: {},
  props: {
    data: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {}
};
