import { mapActions, mapGetters } from "vuex";
import pageWrap from "../page-wrap/index.vue";

export default {
    name: "page-main",
    components: {
        pageWrap,
    },
    data() {
        return {};
    },
    watch: {},
    created() {},
    computed: {
        ...mapGetters({
            page: "pages/page",
            headerMenu: "setting/headerMenu",
        }),
    },
    methods: {
        ...mapActions({}),
    },
};
