import ContentBlockDescription from '@/components/custom/ContentBlock/ContentBlockDescription/index.vue';

export default {
  name: 'ContentBlockVideo',
  components: { ContentBlockDescription },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isPlaying: false,
      isYouTubeLink: false,
      videoUrl: null
    };
  },
  created() {
    let { video } = this.data;
    if (video) {
      let videoId = this._getYouTubeVideoId(video);
      if (videoId) {
        this.videoUrl = videoId;
        this.isYouTubeLink = true;
      } else {
        this.videoUrl = video;
      }
    }
  },
  mounted() {},
  computed: {},
  methods: {
    _getYouTubeVideoId(url) {
      let parsedURL = new URL(url);
      if (
        parsedURL.hostname === 'www.youtube.com' ||
        parsedURL.hostname === 'youtube.com' ||
        parsedURL.hostname === 'm.youtube.com' ||
        parsedURL.hostname === 'youtu.be'
      ) {
        if (parsedURL.hostname === 'youtu.be') {
          return parsedURL.pathname.slice(1); //---short url
        }
        if (parsedURL.pathname.indexOf('embed') > -1) {
          let pathParts = parsedURL.pathname.split('/');
          return pathParts.at(-1);
        }
        // console.log('parsedURL',parsedURL, parsedURL.pathname.slice(1));
        return parsedURL.searchParams.get('v'); //---standard url
      }
      return null; //---not a youtube url
    },
    onPlayVideo() {
      let video = this.$refs.video;
      video.load();
      if (!this.isPlaying) {
        this.isPlaying = true;
        video.play();
      } else {
        this.isPlaying = false;
        video.pause();
      }
    }
  }
};
