export default {
  name: 'ContentBlockDescription',
  components: {},
  props: {
    data: {
      type: String,
      default: () => {}
    },
    positionCenter: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {}
};
