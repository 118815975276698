import ContentBlockDescription from '@/components/custom/ContentBlock/ContentBlockDescription/index.vue';

export default {
  name: 'ContentBlockImage',
  components: { ContentBlockDescription },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {}
};
